.footer{
    background-color: #466745;
    opacity: 90%;
    color: #ffffff;
    font-family: 'Space Mono', monospace;
    height: auto;
    margin-top: 42px;
    padding: 32px 102px 12px 102px;
    display: block;
}

.copyright{
    display: flex;
    margin-right: 12px;
   
}
.container-copy-credits{
    display: flex;
    padding-top: 22px;
}

.credits a {
    text-decoration: underline;
    color: #fff;
    cursor: pointer;
}

@media all and (max-width: 1024px){
    .footer{
        padding: 32px 12px 12px 12px; 
    }
}

@media all and (max-width: 600px){
    .container-copy-credits{
        display: block;
        height: 100%;
    }
    .copyright{
        margin-bottom: 8px;
    }
}