.contancs-socials {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Nunito', sans-serif;
    padding: 0px 12px;
    height: 32px;
    width: 100%;
    background-color: #466745;
    color: #ffffff;
    opacity: 90%;
}

.contancs-socials .socials {
    display: flex;
}

.emailto {
    cursor: pointer;
    text-decoration: underline;
}
.emailto:hover{
    color: cornflowerblue;
}


.social-icons {
    height: 22px;
    width: 32px;
    padding: 1px 5px;
    cursor: pointer;
}

:hover.social-icons {
    height: 25px;
}

#home {
    height: auto;
}

.home {
    background-image: url("../../../public/images/home.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
}

.home h1 {
    font-size: 79px;
}

.home p {
    font-size: 24px;
    font-family: 'Space Mono', monospace;
    margin-top: 32px;
}

.home-text {
    width: 500px;
    text-align: center;
    margin-top: 82px;

}

.container-scroll-up-btn svg path {
    cursor: pointer;
}


.scroll-up {
    background-color: #466745;
    opacity: 94%;
    color: #ffff;
    border-radius: 100%;
    height: 74px;
    width: auto;
    position: fixed;
    bottom: 250px;
    right: 50px;
    border: 1px solid #ffff;
    cursor: pointer;
}

.hide-scroll-up {
    display: none;
}


@media all and (max-width: 600px) {
    .home {
        height: 800px;
    }

    .home h1 {
        font-size: 56px;
    }

    .home-text {
        width: 280px;
        text-align: center;
    }

    .scroll-up {
        height: 62px;
        bottom: 32px;
        right: 6px;

    }
}