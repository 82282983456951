#toaster {
    min-height: 100px;
    max-width: 300px;
    background-color: #ffffff;
    opacity: 97%;
    position: fixed;
    /* Usa position: fixed per rimanere fisso rispetto alla finestra */
    top: 10%;
    /* Posiziona al 50% dall'alto */
    left: 50%;
    /* Posiziona al 50% da sinistra */
    transform: translate(-50%, -50%);
    /* Centra esattamente l'elemento */
    border-radius: 12px;
    border: solid 1px #466745;
    display: flex;
    justify-content: center;
    /* Centro del contenuto orizzontalmente */
    align-items: center;
    /* Centro del contenuto verticalmente */
    z-index: 9999;
    font-family: 'Space Mono', monospace;
}

.toaster-container {
    padding: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.close-btn {
    position: absolute;
    top: 0px;
    right: 18px;
}


.toaster-message{
    margin-top: 5px;
}
