#credits {
    margin: 0px 32px;
    padding: 62px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-items: center;
    font-family: 'Space Mono', monospace;
    /* border: 1px solid blueviolet; */
}

#credits a {
    cursor: pointer;
}

#credits a:hover {
    color: red;
}

.title-credits {
    margin-bottom: 0px !important;
}

.container-text-credits {
    padding-top: 32px;
    height: auto;
}

.container-text-credits ul {
    list-style-type: none;
}

.container-text-credits li {
    margin-top: 12px;
}

.bold-credits {
    font-size: 26px;
}

.bold-credits,
.pictures {
    padding-bottom: 12px;
}

.credits-website {
    text-decoration: none;
    /* color: red; */
}

.back-home-text-container {
    margin-top: 62px;
}


@media all and (max-width: 600px) {
    #credits {
        padding: 12px;
    }
}