#lavora-con-noi {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-items: center;
    font-family: 'Space Mono', monospace;
    height: auto;
    margin: auto 32px;
    padding:42px 42px;
}

.page-container {
    border-top: 1px solid lightslategray;
}

.underline {
    text-decoration: underline;
}

.form-style {
    background-color: #466745;
    color: #ffff;
    opacity: 90%;
    padding: 42px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
}

.form {
    display: flex;
    flex-direction: column;
    /* margin-top: 12px; */
}

.form-submit-btn {
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
    padding: 16px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 18px;
    color: #466745;
    background-color: #ffffff; /* Colore di sfondo per il bottone */
    border: none; /* Rimuove il bordo predefinito */
    -webkit-appearance: none; /* Rimuove lo stile predefinito di iOS/Safari */
    -moz-appearance: none; /* Rimuove lo stile predefinito di Firefox */
    appearance: none; /* Rimuove lo stile predefinito in browser compatibili */
}

.form-submit-btn:hover {
    color: black;
    opacity: 80%;
}

.text-area-form {
    border-radius: 12px;
    color: #466745;
}

.input-text-form {
    width: 100%;
    padding: 6px;
    border-radius: 6px;
    font-family: 'Space Mono', monospace;
    color: #466745;
}

textarea{
    padding: 22px;
}




.form-headers{
    font-size: 28px;
    padding: 32px 0px;
}

@media (max-width: 768px) {
    #lavora-con-noi {
        margin: auto 16px;
        padding: 22px 20px;
    }

    .form-style {
        padding: 40px 16px;
        border-radius: 16px;
    }

    .form-submit-btn {
        font-size: 16px;
        padding: 14px;
    }
}

@media (max-width: 480px) {
    #lavora-con-noi {
        margin: auto 8px;
        padding: 16px 12px;
    }

    .form-style {
        padding: 30px 12px;
        border-radius: 12px;
    }

    .form {
        width: 100%;
    }

    .form-submit-btn {
        font-size: 14px;
        padding: 12px;
    }
}