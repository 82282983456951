header {
    font-family: 'Nunito', sans-serif;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 2rem;
    font-weight: 600;
    font-size: 24px;

}

.logo {
    height: 60px;
    width: 60px
}

.img-logo {
    height: 60px;
    width: 60px;
    cursor: pointer;
}

nav a {
    margin: 0 1rem;
    text-decoration: none;
    color: black;
    cursor: pointer;
}

nav a:hover {
    color: #466745;

}

header .nav-btn {
    padding: 5px;
    cursor: pointer;
    width: 36px;
    background-color: transparent;
    border: none;
    outline: none;
    color: black;
    opacity: 0;
    visibility: hidden;
    font-size: 1.8rem;
}

@media all and (max-width: 1024px) {
    header .nav-btn {
        visibility: visible;
        opacity: 1;
    }

    header nav {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        gap: 1.5rem;
        background-color: white;
        transition: 0.5s;
        transform: translateY(-100%);
        z-index: 9999;
    }

    header .responsive-nav {
        transform: none;
    }

    nav .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
    }

    nav a {
        font-size: 1.5rem;
    }
}