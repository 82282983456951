#privacy-policy{
    /* font-family: 'Space Mono', monospace;
    background-color: #466745;
    opacity: 90%;
    height: auto;
    padding: 102px;
    color: #ffff; */
    margin: auto 32px;
    padding:42px 42px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-items: center;
    font-family: 'Space Mono', monospace;
    height: auto;
}

.privacy-policy-container{
    margin: 62px 0px;
}

.privacy-policy-subcontainer{
    margin: 32px 0px;
}

.privacy-policy-list-container{
    margin: 32px 22px;

}

a{
    text-decoration: underline;
}

a:hover{
color: cornflowerblue;
cursor: pointer;
}

@media (max-width: 768px) {
    #privacy-policy {
        padding: 32px;
        margin: 0px 16px;
    }

    .privacy-policy-container {
        margin: 32px 0px;
    }

    .privacy-policy-subcontainer {
        margin: 16px 0px;
    }

    .privacy-policy-list-container {
        margin: 16px 10px;
    }
}

/* Media query per schermi molto piccoli (es. smartphone piccoli) */
@media (max-width: 480px) {
    #privacy-policy {
        padding: 16px;
        margin: 0px 8px;
    }

    .privacy-policy-container {
        margin: 24px 0px;
    }

    .privacy-policy-subcontainer {
        margin: 12px 0px;
    }

    .privacy-policy-list-container {
        margin: 12px 8px;
    }
}