#contacts {
    font-family: 'Space Mono', monospace;
    padding: 12px;
}

.contacts-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas: "space  contact-info contact-info contact-info container-text container-text";
    gap: 32px;
    height: auto;
}

.space {
    grid-area: space;
    height: auto;
}

.contact-info {
    grid-area: contact-info;
    padding-top: 32px;
    height: auto;
    width: auto;
}

.container-text {
    grid-area: container-text;
    height: auto;
    width: 500px;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 62px;

}

.container-text-header {
    font-size: 28px;
    margin: 22px 0;
    font-weight: 400;
}

.hr-container {
    display: flex;
    justify-content: center;
    margin-bottom: 62px;
}

.hr {
    height: 1px;
    width: 10%;
    border: 1px solid black;
}

.contacts-box {
    display: flex;
    margin-top: 12px;
}

.ag-icons {
    height: 28px;
    width: 28px;
}

.ag-icon-container {
    height: 62px;
    display: flex;
    justify-content: center;
    padding: 8px;
    margin: 0 22px 0 0;
}

.contacts-box-text {
    margin-top: 12px;
    cursor: pointer;
}

.p-contacts {
    letter-spacing: 1.4px;
}

.btn-contacts {
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
    background-color: rgb(245 245 245);
    color: #466745;
    font-weight: 500;
    margin-top: 32px;
    padding: 16px;
    border: 1px solid rgb(246 246 246);
    border-radius: 18px;
    cursor: pointer;
}

a {
    text-decoration: none;
    color: black;
}


.btn-contacts a {
    text-decoration: none;

}

.btn-contacts:hover {
    border: 2px solid #466745;
    opacity: 80%;
    /* color: red; */

}

.img-position {
    height: 400px;
    width: 100%;
    object-fit: cover;
    border: solid 1px lightgrey;
    cursor: pointer;
}

@media all and (max-width: 1024px) {
    .contacts-container {
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas: "contact-info contact-info contact-info container-text ";
    }

    .space {
        display: none;
    }

}

@media all and (max-width: 900px) {
    .contacts-container {
        grid-template-columns: repeat(1, 100%);
        grid-template-areas: "contact-info "
            "container-text";
        margin-top: 62px;
    }

    .container-text {
        width: auto;
        padding: 0;
    }

    h1 {
        font-size: 2.5em;
    }

}