#services {
    display: flex;
    flex-direction: column;
    height: auto;
    width: auto;
    justify-content: center;
    align-items: center;
    padding: 62px 0px;
    font-family: 'Space Mono', monospace;
}

.container-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
    width: auto;
}

.card-services {
    background-color: rgb(245 245 245);
    width: 300px;
    height: auto;
    border: 1px solid rgb(246 246 246);
    border-radius: 12px;
    overflow: hidden;
}

.title {
    font-size: 58px;
    font-weight: 300;
    margin-bottom: 32px;
}

.card-img {
    height: 180px;
    width: 298px;
}

.card-body {
    padding: 20px;
}

.card-body h2 {
    padding-bottom: 12px;
}

.card-body p {
    padding-bottom: 8px;
}


@media all and (max-width: 1024px) {

    .container-cards {
        grid-template-columns: auto auto;
        grid-template-rows: repeat(2, 1fr);
    }
}

@media all and (max-width: 500px) {
    .title-services {
        text-align: center;
    }

    .container-cards {
        grid-template-columns: auto;
        grid-template-rows: repeat(3, 1fr);
    }

    h1 {
        font-size: 2.5em;
    }
}