#about {
    font-family: 'Space Mono', monospace;
    background-color: #466745;
    opacity: 90%;
    height: auto;
    padding: 102px;
    color: #ffff;
}

.about-info {
    font-size: 22px;
}

@media all and (max-width: 600px) {
    #about {
        padding: 62px 12px;
        height: auto;
    }

    .title {
        font-size: 2.5em;
    }
}